.map-wrap {
  position: relative;
  width: 100%;
  height: 434px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
