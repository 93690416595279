.p-datatable {
  margin: 30px 21px;
  padding: 8px 20px;
  border-radius: 8px;
  margin-bottom: 2rem;
  border: 1px solid #e6edff;
}

.p-datatable-tbody {
  height: 8px;
}

.p-column-title {
  color: rgba(0, 0, 0, 0.87);
}

.p-datatable .p-datatable-tbody > tr {
  color: rgba(0, 0, 0, 0.87);
}
